import React, { useState, useEffect } from "react";
import "./css/Home.css";
import HourlyWeather from "./HourlyWeather";
import Maps from "./Maps";
import {
  WbSunny,
  AcUnit,
  Opacity,
  Air,
  Cloud,
  Thermostat,
} from "@mui/icons-material";
import config from '../config';

function Home() {
  // State variables
  const [locationData, setLocationData] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [cityCenter, setCityCenter] = useState(null);
  const [searchClicked, setSearchClicked] = useState(false);
  const [hourlyWeatherData, setHourlyWeatherData] = useState([]);

  // Function to handle search
  const handleSearch = () => {
    setSearchClicked(true);

    if (searchTerm && searchTerm.trim()) {
      fetch(`https://api.openweathermap.org/data/2.5/weather?q=${searchTerm}&appid=${config.OPENWEATHERMAP_API_KEY}`)
        .then((response) => response.json())
        .then((data) => {
          setLocationData(data);
          setCityCenter([data.coord.lat, data.coord.lon]);
        })
        .catch((error) => {
          console.error("Error fetching weather data:", error);
        });
    }
  };

  // Debounce function definition
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // Debounce the search function
  const debouncedHandleSearch = debounce(handleSearch, 500);

  // Function to get the local time
  const getCurrentCityTime = (timezone) => {
    if (typeof timezone !== 'number') {
      console.error('Invalid time zone offset:', timezone);
      return '';
    }

    const currentUTC = new Date();
    const localTime = new Date(currentUTC.getTime() + (timezone + currentUTC.getTimezoneOffset() * 60) * 1000);
    return localTime.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      timeZoneName: 'short',
    });
  };

  // Function to get current location
  const handleGetCurrentLocation = () => {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported in this browser');
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setCityCenter([latitude, longitude]);

        fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${config.OPENWEATHERMAP_API_KEY}`)
          .then((response) => response.json())
          .then((data) => {
            const cityName = data.name;
            setLocationData(data);
            setSearchTerm(cityName);

            // Fetch hourly weather data for the current location
            fetch(`https://api.openweathermap.org/data/2.5/forecast?q=${cityName}&appid=${config.OPENWEATHERMAP_API_KEY}`)
              .then((hourlyResponse) => hourlyResponse.json())
              .then((hourlyData) => {
                setHourlyWeatherData(hourlyData);
                setSearchClicked(true); // Trigger rendering with new data
              })
              .catch((error) => {
                console.error("Error fetching hourly weather data:", error);
              });
          })
          .catch((error) => {
            console.error("Error fetching weather data:", error);
          });
      },
      (error) => {
        console.error("Error getting user's location:", error);
      }
    );
  };
  
 const getWeatherIcon = () => {
    if (locationData && locationData.weather && locationData.weather[0] && locationData.weather[0].main) {
      const weatherCondition = locationData.weather[0].main;
      switch (weatherCondition) {
        case "Clear":
          return <WbSunny />;
        case "Rain":
          return <Opacity />;
        case "Snow":
          return <AcUnit />;
        case "Clouds":
          return <Cloud />;
        default:
          return null;
      }
    }
    return null;
  };

  return (
    <div className="scrollable-content">
               <div className="container-wrapper">
                  <div className="home-container">
                    <h1>Weather Forecast</h1>
                        <div className="searchbar">
                          <input
                              type="text"
                              placeholder="Search for a location..."
                              value={searchTerm}
                              onChange={(e) => {
                                setSearchTerm(e.target.value); // Update search term as the user types
                              }}
                            />
                          
                          <button onClick={handleSearch}>Search</button>
                          <button
                            className="get-location-button"
                            onClick={handleGetCurrentLocation}
                          >
                            Get Current Location
                          </button>
                       </div>
                  </div>
               </div>
            

                    <div className="LocMap">
                      {locationData && (
                        <div className="LocInfo">
                          <h2>Location Information</h2>
                          <p>Name: {locationData.name}</p>
                          {locationData.sys && <p>Country: {locationData.sys.country}</p>}
                          {locationData.timezone && (
                            <p>Current Time: {getCurrentCityTime(locationData.timezone)}</p>
                          )}
                          {getWeatherIcon()}
                        </div>
                        )}
                    </div>
                    
                    <div className="map-container">
                      <div className=""></div>
                      <Maps locationData={locationData} cityCenter={cityCenter} />
                    </div>
                   <div className="today-wrap">
                  <h1 className="today-heading">Today</h1>
              <div className="LocAdd">
                  {locationData && (
                              <div className="WeatherInfo">
                                <h2>Weather Information</h2>
                                    <div>
                                    <p>
                                      Temperature:{" "}
                                      {locationData.main &&
                                        locationData.main.temp &&
                                        (locationData.main.temp - 273.15).toFixed(1)}{" "}
                                      °C <Thermostat />
                                    </p>
                                    <p>Humidity: {locationData.main && locationData.main.humidity} %</p>
                                    <p>
                                      Wind Speed:{" "}
                                      {locationData.wind &&
                                        locationData.wind.speed &&
                                        locationData.wind.speed.toFixed(2)}{" "}
                                      m/s <Air />
                                    </p>
                                    <p>Visibility: {locationData.visibility} m</p>
                                    </div>
                                </div>
                                  )}

                      {locationData && locationData.sys && (
                                    <div className="AddInfo">
                                      <h2>Additional Information</h2>
                                      <p>
                                        Sunrise:{" "}
                                        {locationData.sys.sunrise &&
                                          new Date(locationData.sys.sunrise * 1000).toLocaleTimeString()}
                                      </p>
                                      <p>
                                        Sunset:{" "}
                                        {locationData.sys.sunset &&
                                          new Date(locationData.sys.sunset * 1000).toLocaleTimeString()}
                                      </p>
                                    </div>
                                )}
                </div>
                </div>


                  <div className="Hourly">
                    {locationData ? (
                      <div>
                        <HourlyWeather searchLocation={searchTerm} hourlyData={hourlyWeatherData} />
                      </div>
                    ) : (
                      <p>Loading hourly weather data...</p>
                    )}
                  </div>
        
     
     
      
    </div>
  );
}

export default Home;
