import React, { useEffect, useState } from 'react';
import './css/HourlyWeather.css';
import config from '../config';

const HourlyWeather = ({ searchLocation }) => {
  const [hourlyData, setHourlyData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!searchLocation) {
        // Reset the data and error if there is no searchLocation
        setHourlyData([]);
        setError(null);
        return;
      }

      try {
        const response = await fetch(
          `https://api.openweathermap.org/data/2.5/forecast?q=${searchLocation}&appid=${config.OPENWEATHERMAP_API_KEY}`
        );

        if (!response.ok) {
          // Check for 404 response (Not Found)
          if (response.status === 404) {
            setError('Location not found.');
          } else {
            throw new Error('Network response was not ok');
          }
        }

        const data = await response.json();

        if (data && data.list) {
          const currentDate = new Date();
          const endOfDay = new Date(currentDate);
          endOfDay.setHours(23, 59, 59, 999); // Set to the end of the day

          // Filter the data for the present day
          const filteredData = data.list.filter((item) => {
            const itemDate = new Date(item.dt_txt);
            return itemDate >= currentDate && itemDate <= endOfDay;
          });

          setHourlyData(filteredData);
          setError(null);
        } else {
          setError('No hourly data available.');
        }
      } catch (err) {
        console.error('Error fetching hourly data:', err);
        setError('Failed to fetch data');
      }
    };

    fetchData();
  }, [searchLocation]);

  return (
    <div>
      {hourlyData.length > 0 && (
        <>
          <h2 className="hourly-forecast-title">Hourly Weather Forecast</h2>
          <div className="hourly-forecast-container">
            <ul className="hourly-forecast-list">
              {hourlyData.map((hourlyData, index) => (
                <li key={index} className="hourly-forecast-item">
                  <p className="hour">{hourlyData.dt_txt.split(' ')[1]}</p>
                  <p className="temperature">Temperature: {(hourlyData.main.temp - 273.15).toFixed(1)}°C</p>
                  <p className="condition">Weather Condition: {hourlyData.weather[0].description}</p>
                  <p className="humidity">Humidity: {hourlyData.main.humidity}%</p>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default HourlyWeather;
