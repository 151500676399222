import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "./css/Maps.css";
import "leaflet/dist/leaflet.css";

const Maps = ({ locationData, cityCenter }) => {
  // Check if locationData is available
  if (!locationData || !locationData.coord) {
    return null; // Return null if locationData is not available
  }

  const mapKey = `${locationData.coord.lat}-${locationData.coord.lon}`;

  return (
    <div className="map-container">
      <MapContainer key={mapKey} center={[locationData.coord.lat, locationData.coord.lon]} zoom={13}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {cityCenter && (
          <Marker position={cityCenter}>
            <Popup>City Center</Popup>
          </Marker>
        )}
      </MapContainer>
    </div>
  );
};

export default Maps;
