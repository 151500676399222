import React, { useState } from 'react';
import './App.css';
import Home from './components/Home';

function App() {
  const [weatherData, setWeatherData] = useState(null);

  return (
    <div className="app">
      <Home setWeatherData={setWeatherData} />
      {/* Add your name at the bottom center */}
      <div className="bottom-center">Akash Mathane</div>
    </div>
  );
}

export default App;
